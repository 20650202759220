import { Button, CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import IotServiceClient from 'clients/IotServiceClient';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import CanvasJSReact from "@canvasjs/react-charts";

const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function UserBasedDashboard() {
  const { enqueueSnackbar } = useSnackbar();
  const [text, setText] = useState("");
  const [showGraphs, setShowGraphs] = useState(false);
  
  const [showAirQualitysensor, setShowAirQualitysensor] = useState(false);
  const [no2ChartOptions, setNo2ChartOptions] = useState({});
  const [nh3ChartOptions, setNh3ChartOptions] = useState({});
  const [coChartOptions, setCoChartOptions] = useState({});


  const [showAirQuality2sensor, setShowAir2Qualitysensor] = useState(false);
  const [rstcChartOptions, setRstcChartOptions] = useState({});
  const [tvocChartOptions, setTvocChartOptions] = useState({});
  

  const [showCpuAndMemory, setShowCpuAndMemory] = useState(false);
  const [cpuChartOptions, setCpuChartOptions] = useState({});
  const [memoryChartOptions, setMemoryChartOptions] = useState({});

  const [showCo2Sensor, setShowCo2Sensor] = useState(false);
  const [co2ChartOptions, setCo2ChartOptions] = useState({});
  const [tempChartOptions, setTempChartOptions] = useState({});
  const [showLoading, setShowLoading] = useState(false);
  const chartRef = useRef(null);

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const clearData = () => {
    setShowAirQualitysensor(false);
    setShowAir2Qualitysensor(false);
    setShowCpuAndMemory(false);
    setShowCo2Sensor(false);
    setNo2ChartOptions({});
    setNh3ChartOptions({});
    setCoChartOptions({});

    setRstcChartOptions({});
    setTvocChartOptions({});
    
    setCpuChartOptions({});
    setMemoryChartOptions({});

    setCo2ChartOptions({});
    setTempChartOptions({});

  }

  const generateGraphs = () => {
    clearData();
    setShowLoading(true);
    IotServiceClient.generateGraphs(text).then((response) => {
      setShowLoading(false);
      if (Array.isArray(response) && response.length > 0) {        
        updateChart(response);
        setShowGraphs(true);
      } else if (response.hasOwnproperty("message")) {
        enqueueSnackbar("Failed to fetch data. Please re-phrase the text");
      } else {
        enqueueSnackbar("No data available to plot.");
      }
    })
  };

  const getTitle = (name) => {
    if (name === "cpu"){      
      return "CPU Usage";
    }else if (name === "mm"){
      return "Memory Usage";
    }else if (name === "rstc"){
      return "Resistance";
    }else if (name === "tvoc"){
      return "Total Volatile Organic Compounds";
    }else if (name === "nh3"){
      return "NH₃";
    }else if (name === "no2"){
      return "NO₂";
    }else if (name === "co"){
      return "CO";
    }else if (name === "co2"){
      return "CO₂";
    }else if (name === "temp"){
      return "Temperature₂";
    }
  }

  const getTooltipContent = (name) => {
    if (name === "cpu"){      
      return "<b>{formattedDataX}</b><br/>CPU Usage: <b>{y}</b> %";
    }else if (name === "mm"){
      return "<b>{formattedDataX}</b><br/>Memory Usage: <b>{y}</b> MB";
    }else if (name === "rstc"){
      return "<b>{formattedDataX}</b><br/>RSTC: <b>{y}</b> OHMS";
    }else if (name === "tvoc"){
      return "<b>{formattedDataX}</b><br/>TVOC: <b>{y}</b> PPB";
    }else if (name === "nh3"){
      return "<b>{formattedDataX}</b><br/>NH₃: <b>{y}</b> PPM";
    }else if (name === "no2"){
      return "<b>{formattedDataX}</b><br/>NO₂: <b>{y}</b> PPM";
    }else if (name === "co"){
      return "<b>{formattedDataX}</b><br/>CO: <b>{y}</b> PPM";
    }else if (name === "co2"){
      return "<b>{formattedDataX}</b><br/>CO₂: <b>{y}</b> PPM";
    }else if (name === "temp"){
      return "<b>{formattedDataX}</b><br/>Temp: <b>{y}</b> °C";
    }
  }

  const setDataForSensors = (name, sensorData) => {
    const timeStamps = sensorData.map(d => d.x.getTime());
    const minX = new Date(Math.min(...timeStamps) - 60 * 60 * 1000); // -1hr
    const maxX = new Date(Math.max(...timeStamps) + 60 * 60 * 1000); // +1hr
    const yValues = sensorData.map(d => d.y);
    const minY = Math.min(...yValues) - 5;
    const maxY = Math.max(...yValues) + 10;

    const options = {
      animationEnabled: true,
      zoomEnabled: true,
      height: 400,
      axisX: {
        title: getTitle(name),
        minimum: minX,
        maximum: maxX,
        labelAngle: -45,
        labelFontSize: 14,
        gridThickness: 0,
        labelFontSize: 16,
        labelAngle: 180,
        labelTextAlign: "center",
        valueFormatString: "MMM DD, YYYY hh:mm TT",
        labelFormatter: (e) => CanvasJS.formatDate(e.value, "MMM DD, YYYY hh:mm TT"),
        gridColor: "transparent",
      },
      axisY: {
        minimum: minY,
        maximum: maxY,
        gridColor: "transparent",
        labelWrap: true,
        labelFontSize: 14,
      },
      data: [{
        type: "line",
        lineColor: "orange",
        lineThickness: 2,
        markerSize: 4,
        toolTipContent: getTooltipContent(name),
        dataPoints: sensorData
      }]
    };
    if (name === "cpu"){
      setCpuChartOptions(options);
    }else if (name === "mm"){
      setMemoryChartOptions(options);
    }else if (name === "rstc"){
      setRstcChartOptions(options);
    }else if (name === "tvoc"){
      setTvocChartOptions(options);
    }else if (name === "nh3"){
      setNh3ChartOptions(options);
    }else if (name === "no2"){
      setNo2ChartOptions(options);
    }else if (name === "co"){
      setCoChartOptions(options)
    }else if (name === "co2"){
      setCo2ChartOptions(options)
    }else if (name === "temp"){
      setTempChartOptions(options);
    }
  }

  const updateChart = (data) => {
    let CO = [], NH3 = [], NO2 = [], RSTC = [], TVOC = [], CPU = [], MM = [], CO2 = [], TEMP = [];
    let showSensorData = "";
    data.map(entry => {
      const timestamp = new Date(Number(entry.TI));
      if (entry.hasOwnProperty("CO")){
        if (showSensorData === ""){          
          showSensorData = "airquality2";
        }
        CO.push({
          x: timestamp,
          y: entry.CO,
          formattedDataX: timestamp.toLocaleString()
        })
        NH3.push({
          x: timestamp,
          y: entry.NH3,
          formattedDataX: timestamp.toLocaleString()
        })
        NO2.push({
          x: timestamp,
          y: entry.NO2,
          formattedDataX: timestamp.toLocaleString()
        })
      }else if (entry.hasOwnProperty("RSTC")){
        if (showSensorData === ""){
          showSensorData = "airquality";
        }
        RSTC.push({
          x: timestamp,
          y: entry.RSTC,
          formattedDataX: timestamp.toLocaleString()
        })
        TVOC.push({
          x: timestamp,
          y: entry.TVOC,
          formattedDataX: timestamp.toLocaleString()
        })
      }else if (entry.hasOwnProperty("CP")){
        if (showSensorData === ""){
          showSensorData = "CpuAndMemory";
        }
        CPU.push({
          x: timestamp,
          y: entry.CP,
          formattedDataX: timestamp.toLocaleString()
        })
        MM.push({
          x: timestamp,
          y: entry.MM,
          formattedDataX: timestamp.toLocaleString()
        })
      }else if (entry.hasOwnProperty("TEMP")){
        if (showSensorData === ""){
          showSensorData = "Co2Sensor";
        }
        CO2.push({
          x: timestamp,
          y: entry.CO2,
          formattedDataX: timestamp.toLocaleString()
        })
        TEMP.push({
          x: timestamp,
          y: entry.TEMP,
          formattedDataX: timestamp.toLocaleString()
        })
      }
    });
    
    if (showSensorData === "CpuAndMemory"){
      setShowCpuAndMemory(true);
      setDataForSensors("cpu", CPU);
      setDataForSensors("mm", MM);
    }else if (showSensorData === "airquality"){
      setShowAirQualitysensor(true);
      setDataForSensors("rstc", RSTC);
      setDataForSensors("tvoc", TVOC);
    }else if (showSensorData === "airquality2"){
      setShowAir2Qualitysensor(true)
      setDataForSensors("co", CO);
      setDataForSensors("nh3", NH3);
      setDataForSensors("no2", NO2);
    }else if (showSensorData === "Co2Sensor"){
      setShowCo2Sensor(true);
      setDataForSensors("co2", CO2);
      setDataForSensors("temp", TEMP);
    }
    
  };

  useEffect(() => {
    return () => {
      clearData();
    };
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Box sx={{ width: "100%", maxWidth: "800px", padding: 3 }}>
        <textarea
          placeholder="Type a request, e.g., 'Co2 sensor data for MacId(d0:33:04:02:05:02) for last 10 days / 3 months / or in between specific(from-to clause) dates in dd-mm-yyyy format'"
          value={text}
          onChange={handleTextChange}
          style={{
            width: "100%",
            height: "120px",
            fontSize: "16px",
            padding: "16px",
            borderRadius: "8px",
            border: "1px solid #ccc",
            resize: "none",
            marginBottom: "16px"
          }}
        />
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            disabled={!text.trim()}
            onClick={generateGraphs}
          >
            Generate
          </Button>
        </Box>
      </Box>

      {showGraphs && !showLoading && (
        showAirQuality2sensor ?
          <Box sx={{display:"flex", height:"calc(100vh - 300px)", width: "100%", algnItems:"center", overflow:"scroll", flexDirection:"column"}}>
            <Box sx={{ width: "100%",  padding: 2 }}>
              <CanvasJSChart options={no2ChartOptions}/>
            </Box>
            <Box sx={{ width: "100%",  padding: 2 }}>
              <CanvasJSChart options={nh3ChartOptions} />
            </Box>
            <Box sx={{ width: "100%",  padding: 2 }}>
              <CanvasJSChart options={coChartOptions} />
            </Box>
          </Box>
        :
          showAirQualitysensor ?
            <Box sx={{display:"flex", height:"calc(100vh - 300px)", width: "100%", algnItems:"center", overflow:"scroll", flexDirection:"column"}}>
              <Box sx={{ width: "100%",  padding: 2 }}>
                <CanvasJSChart options={rstcChartOptions}/>
              </Box>
              <Box sx={{ width: "100%",  padding: 2 }}>
                <CanvasJSChart options={tvocChartOptions} />
              </Box>
            </Box>
          :
            showCpuAndMemory ?
              <Box sx={{display:"flex", height:"calc(100vh - 300px)", width: "100%", algnItems:"center", overflow:"scroll", flexDirection:"column"}}>
                <Box sx={{ width: "100%",  padding: 2 }}>
                  <CanvasJSChart options={cpuChartOptions}/>
                </Box>
                <Box sx={{ width: "100%",  padding: 2 }}>
                  <CanvasJSChart options={memoryChartOptions} />
                </Box>
              </Box>
            :
              showCo2Sensor ?
                <Box sx={{display:"flex", height:"calc(100vh - 300px)", width: "100%", algnItems:"center", overflow:"scroll", flexDirection:"column"}}>
                  <Box sx={{ width: "100%",  padding: 2 }}>
                    <CanvasJSChart options={co2ChartOptions}/>
                  </Box>
                  <Box sx={{ width: "100%",  padding: 2 }}>
                    <CanvasJSChart options={tempChartOptions} />
                  </Box>
                </Box>
              :
                null

      )}  
      {showLoading && (
        <Box sx={{display:"flex", justifyContent:"center", alignItems:"center", height:"calc(100vh - 300px)", width: "100%", algnItems:"center", overflow:"scroll", flexDirection:"column"}}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}
