import Slide from '@mui/material/Slide';
import PortalConfig from 'components/accountMenu/PortalConfig';
import ThemeConfig from 'components/accountMenu/ThemeConfig';
import UpdateOs from 'components/accountMenu/UpdateOs';
import UpgradeFirmware from 'components/accountMenu/UpgradeFirmware';
import CustomerFirmware from 'components/accountMenu/CustomerFirmware';
import ChangePassword from 'components/changepassword/ChangePassword';
import GlobalDashboard from 'components/GlobalDashboardPage.js';
import LandingPage from 'components/landingPage/LandingPage.js';
import Login from 'components/login/Login.js';
import SideNav from 'components/sideNavBar/SideNav';
import FallbackUi from 'helper-components/FallbackUi';
import { SnackbarProvider } from 'notistack';
import 'nprogress/nprogress.css';
import React, { Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import 'stylesheet/CustomisingNprogress.css';


function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

const App = () => {

    const location = useLocation();
    const [hash, setHash] = React.useState(window.location.hash);

    const handleHashChange = () => {
        setHash(window.location.hash);
    };

    const isAuthenticated = () => {
        return sessionStorage.hasOwnProperty("userDetails") ? true : false ;
    }

    React.useEffect(() => {
        window.addEventListener('hashchange', handleHashChange);

        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, []);

    const renderComponent = () => {

        if (window.location.href.indexOf("portal-user/reset-password/") === -1 && window.location.href.indexOf("com/reset-password/") === -1) {
            if (hash === '#/home' || hash === '#/logout' || hash === '/' || hash === '') {

                if(isAuthenticated() && sessionStorage.isRootUser ===  "true"){
                    window.location.hash = "/admin/tenants";
                    location.hash = "#/admin/tenants";
                }else if(isAuthenticated() && sessionStorage.isRootUser ===  "false"){
                    window.location.hash = "/cli/dashboard";
                    location.hash = "#/cli/dashboard";
                }else {
                    return (
                        <Suspense fallback={<FallbackUi />} >                            
                            <Login />
                        </Suspense>
                    )
                }
            } else if (hash.startsWith('#/admin/tenants') && isAuthenticated()) {
                return (
                    <SnackbarProvider autoHideDuration={2000} maxSnack={3} TransitionComponent={TransitionUp} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                        <LandingPage />
                    </SnackbarProvider>
                );
            } else if (hash.startsWith("#/global-search") && isAuthenticated()) {
                return (
                    <SnackbarProvider autoHideDuration={2000} maxSnack={3} TransitionComponent={TransitionUp} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                        <GlobalDashboard />
                    </SnackbarProvider>
                );
            } else if (hash.startsWith("#/cli/") && isAuthenticated()) {
                return (
                    <SnackbarProvider autoHideDuration={2000} maxSnack={3} TransitionComponent={TransitionUp} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                        <SideNav />
                    </SnackbarProvider>
                );
            } else if (hash.startsWith("#/change-password") && isAuthenticated()) {
                 return (
                     <SnackbarProvider autoHideDuration={2000} maxSnack={3} TransitionComponent={TransitionUp} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                         <ChangePassword />;
                     </SnackbarProvider>
                );
            } else if (hash.startsWith("#/upgrade-firmware") && isAuthenticated()) {
                return (
                    <SnackbarProvider autoHideDuration={2000} maxSnack={3} TransitionComponent={TransitionUp} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                        <UpgradeFirmware />;
                    </SnackbarProvider>
                );
            } else if (hash.startsWith("#/customer-firmware") && isAuthenticated()) {
                 return (
                     <SnackbarProvider autoHideDuration={2000} maxSnack={3} TransitionComponent={TransitionUp} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                         <CustomerFirmware />;
                     </SnackbarProvider>
                 );
            } else if (hash.startsWith("#/update-OS") && isAuthenticated()) {
               return (
                   <SnackbarProvider autoHideDuration={2000} maxSnack={3} TransitionComponent={TransitionUp} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                       <UpdateOs />;
                   </SnackbarProvider>
               );
            } else if (hash.startsWith("#/theme-config") && isAuthenticated()) {
                return (
                    <SnackbarProvider autoHideDuration={2000} maxSnack={3} TransitionComponent={TransitionUp} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                        <ThemeConfig />;
                    </SnackbarProvider>
                );
            }  else if (hash.startsWith("#/portal-config") && isAuthenticated()) {
                return (
                    <SnackbarProvider autoHideDuration={2000} maxSnack={3} TransitionComponent={TransitionUp} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                        <PortalConfig />;
                    </SnackbarProvider>
                );
            }else {
                if(isAuthenticated() && sessionStorage.isRootUser ===  "true"){
                    window.location.hash = "/admin/tenants";
                    location.hash = "#/admin/tenants";
                }else if(isAuthenticated() && sessionStorage.isRootUser ===  "false"){
                    window.location.hash = "/cli/dashboard";
                    location.hash = "#/cli/dashboard";
                }else {
                    window.location.hash = "/logout";
                    location.hash = "#/logout";
                    return (
                        <Suspense fallback={<FallbackUi />} >
                            <Login />
                        </Suspense>
                    )
                }
            }
        }else {
            if(isAuthenticated()){
                window.location.pathname = "/";
            }else {
                return (
                    <SnackbarProvider autoHideDuration={2000} maxSnack={3} TransitionComponent={TransitionUp} anchorOrigin={{vertical: 'bottom',horizontal: 'right'}}>
                         <ChangePassword isResetPassword={true} resetIotUserPassword={window.location.href.indexOf("com/reset-password/") !== -1 ? true : false} navigatedThroughMail={true} />
                    </SnackbarProvider>
                )
            }
        }
    };

    return (
        <div style={{height : "100%"}}>
            {renderComponent()}
        </div>
    );
};

export default App