import { Tooltip } from '@material-ui/core';
import { AccountCircle, Search, ViewList, ViewModule } from '@mui/icons-material';
import { Box, Divider, IconButton, Menu, MenuItem, Switch } from '@mui/material';
import Slide from '@mui/material/Slide';
import DisableTwoFactorAuthentication from 'components/accountMenu/DisableTwoFactorAuthentication';
import TwoFactorAuthentication from 'components/accountMenu/TwoFactorAuthentication';
import MqttEventHandler from 'components/Mqtt/MqttEventHandler';
import checkDataExistsOrNot from 'helper-components/checkDataExistsOrNot';
import PubSubChannels from 'pubsub/PubSubChannels';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import 'stylesheet/header.css';
import DeletePortalUsersDialog from 'components/DashboardPortalUsers/DeletePortalUserDialog';
import AuthClient from 'clients/AuthClient';


function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

const Appheader = (props) => {

  const location = useLocation();
  const checkHashForAccountMenuAndRootUser = () => {
    if( (window.location.hash.indexOf("change-password") !== -1 || window.location.hash.indexOf("upgrade-firmware") !== -1 || window.location.hash.indexOf("update-OS") !== -1 || window.location.hash.indexOf("portal-config") !== -1 || window.location.hash.indexOf("theme-config") !== -1 || window.location.hash.indexOf("customer-firmware") !== -1) && sessionStorage.isRootUser === "true" ){
      return true;
    }else{
      return false;
    }
  }
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [lockView, setLockView] = React.useState(sessionStorage.lockView === "listView" || sessionStorage.tenantName === "FreeTier" || !sessionStorage.lockView  ? "listView" : "gridView");
  const [imgUrl, setImgUrl] = React.useState("");
  const [primaryThemeColor, setPrimaryThemeColor] = React.useState( checkHashForAccountMenuAndRootUser() ? "#4190c7" : checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7");
  const [secondaryThemeColor, setSecondaryThemeColor] = React.useState(checkHashForAccountMenuAndRootUser() ? "#ffffff" : checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#ffffff")
  const open = Boolean(anchorEl);
  const [logoURL, setLogoURL] = React.useState(checkHashForAccountMenuAndRootUser() ? "./logo.png" : checkDataExistsOrNot(sessionStorage.logoUrl) && sessionStorage.logoUrl !== "null" ? sessionStorage.logoUrl : "./logo.png");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [landingPage, setLandingPage] = React.useState(false);
  const [enableTwoFaDialog, setEnableTwoFaDialog] = React.useState(false);
  const [disableTwoFaDialog, setDisableTwoFaDialog] = React.useState(false);
  const [toggleState, setToggleState] = React.useState(false);
  const [showRemoveUserDialog, setShowRemoveUserDialog] = React.useState(false);

  //const nav = useNavigate();
  const reloadPage = (url) => {
    if (url === "/engineering-View"){
      sessionStorage.engineeringView = true;
    }
    if (url === "/logout"){
        let payload = {
          "scopeId": JSON.parse(sessionStorage.userDetails).user.scopeId,
          "email": JSON.parse(sessionStorage.userDetails).user.email,
          "logout" : {"ti" : [new Date().getTime()]}
        };
        AuthClient.saveLoginOrLogoutActivity(payload,sessionStorage.activityKey,"logout").then((response) => {});
        sessionStorage.clear();
      }
      window.location.hash = url;
      location.hash = '#'+url;
  };

  const checkLocatiionHashChange = () => {
    window.addEventListener("hashchange", function(e){ 
      setLockView("listView");
    });
  }

  const isCariboutechDomain = () => {
    return JSON.parse(sessionStorage.userDetails).user.email.indexOf("cariboutech.com") !==-1
  }

  const isWeskoDomain = () => {
    return JSON.parse(sessionStorage.userDetails).user.email.indexOf("wesko-elocks.com") !== -1;
  }

  const checkRootOrFreeTire = () => {
    return sessionStorage.tenantName === "Wesko" || sessionStorage.tenantName === "FreeTier" ? true : false;
  }  

  const toggleTwoFaState = (data) => {
     data = checkDataExistsOrNot(data) ? JSON.parse(data.toString()) : "" ;
     if (data.hasOwnProperty("user")){
        setToggleState(data.user.secureAuthentication);
        let json = {};
        json.isRootUser = JSON.parse(sessionStorage.userDetails).isRootUser;
        json.permissions = JSON.parse(sessionStorage.userDetails).permissions;
        json.user = data.user;
        sessionStorage.userDetails = JSON.stringify(json);
     }
  }

  React.useEffect(()=>{
    handleOnAction();
    window.addEventListener('hashchange', handleOnAction);
    checkLocatiionHashChange();
    window.twoFAStatusChannel = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.TWO_FACTOR_AUTHENTICATION_CHANNEL.replace("scopeId",JSON.parse(sessionStorage.userDetails).user.scopeId), "Appheader",toggleTwoFaState);
    if (JSON.parse(sessionStorage.userDetails).user.secureAuthentication === true) {
       setToggleState(true);
    } else {
       setToggleState(false);
    }
    if(window.location.hash.indexOf("admin/tenants") !== -1 && !landingPage){
        setLandingPage(true);
    }else if (logoURL != "./logo.png" && sessionStorage.logoUrl && sessionStorage.logoUrl !== "null" ){
        setLogoURL(sessionStorage.logoUrl)
    }else {
      setLogoURL("./logo.png")
    }
    return () => {
      window.removeEventListener('hashchange', handleOnAction);
      if(window.twoFAStatusChannel) {
          window.twoFAStatusChannel.unsubscribe();
          delete window.twoFAStatusChannel;
      }
    }
  },[])

  // IDLE LOGOUT LOGIC
    const timeout = 900000;
    const handleOnIdle = () => {
        let urlHash = window.location.hash;
        if (urlHash !== "" && urlHash !== "#/" && urlHash !== "#/login" && urlHash !== "#/logout"){
            window.location.hash = "/logout";
            location.hash = "#/logout";
        }
    }

    const handleLogoAndUserIconColor = () => {
      if(checkHashForAccountMenuAndRootUser()){
        setLogoURL("/logo.png" );
      }else if (window.location.hash.indexOf("admin/tenants") === -1 && window.location.hash !== ""){
        setLogoURL(checkDataExistsOrNot(sessionStorage.logoUrl) && sessionStorage.logoUrl !== "null" ? sessionStorage.logoUrl : "/logo.png")
      }else {
        setLogoURL("./logo.png");
        setPrimaryThemeColor("#4190c7")
        sessionStorage.tenantPrimaryThemeColor="#4190c7"
        sessionStorage.logoUrl="./logo.png"
      }
      if(window.location.hash.indexOf("admin/tenants") !== -1 && !landingPage){
        setLandingPage(true);
      }
    }

    const enableOrDisableTwoFa = (event) => {
      event.stopPropagation();
      setToggleState(!toggleState);
      setAnchorEl(null);
      if (JSON.parse(sessionStorage.userDetails).user.secureAuthentication === true) {
          setDisableTwoFaDialog(true);
      } else {
          setEnableTwoFaDialog(true);
      }
    }

    const handleOnAction = (event) => {
       handleLogoAndUserIconColor();
    }

    const showRemoveUserAlertDialog = () => {
        setShowRemoveUserDialog(true);
    }

    const handleCloseRemoveUserAlertDialog = () => {
        setShowRemoveUserDialog(false);
    }


  return (
    <nav style={{ width: props.hasOwnProperty('parent') ? '100%' : null,color: '#0000008A' , padding : window.location.hash.indexOf("cli") !== -1 ? "0px 0px 0px 16px" : "0px 16px"}}>
      <div className="div-header" style={{marginLeft: window.location.hash.indexOf("cli") === -1 ? "20px" : "0px",marginTop: window.location.hash.indexOf("cli") === -1 ? "0px" : "0px"}}>
        <img
          className="logo-img imgSize"
          style={{
            padding: window.location.hash.indexOf("cli") === -1 ? '4px' : "0px",
            marginLeft: props.hasOwnProperty('parent') ? "0px" : '22px',
          }}
          src={checkDataExistsOrNot(props.logo) ? props.logo : logoURL}
          alt="logo"
          onClick={() => {
            if (window.location.hash !== "" && window.location.hash !== "#/" && window.location.hash !== "#/admin/tenants"){
                let isInsideSideNav = false;
                if(window.location.hash.indexOf("cli") !== -1){
                    isInsideSideNav = true;
                }
                if(sessionStorage.isRootUser === "true"){
                  delete sessionStorage.sidenavAlreadySelectedItem
                  delete sessionStorage.tenantPrimaryThemeColor
                  delete sessionStorage.tenantSecondaryThemeColor
                  delete sessionStorage.logoUrl
                  window.location.hash = "/admin/tenants";
                  location.hash = "#/admin/tenants";
                }else {
                  window.location.hash = "/cli/dashboard";
                  location.hash = "#/cli/dashboard";
                }
                delete sessionStorage.sidenavAlreadySelectedItem;
            }
          }
          }
        />
        <div className="top-right-items">
          {(window.location.hash !== "" && window.location.hash !== "#/") && sessionStorage.userLoggedIn  ? (
            <React.Fragment>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                {
                landingPage ? (
                  <Link
                    to="#/global-search"
                    style={{textDecoration: 'unset', color: 'black'}}
                    onClick={() => reloadPage('/global-search')}
                  >
                    <IconButton
                      size="small"
                      sx={{ml: 2, color: primaryThemeColor, marginLeft: '0px'}}
                    >
                      <Search sx={{width: 24, height: 24}} />
                    </IconButton>
                  </Link>
                ) : null}
                {
                  !sessionStorage.engineeringView && false?
                    sessionStorage.sidenavAlreadySelectedItem && sessionStorage.tenantName !== "FreeTier" ?
                      sessionStorage.sidenavAlreadySelectedItem === "Locks" ?
                        sessionStorage.lockView === "listView" || !sessionStorage.lockView ?
                          <Tooltip placement='bottom' title="Toggle view" arrow>
                            <ViewModule sx={{color:"#fff",cursor:"pointer"}} onClick={()=>{
                              setLockView("gridView");
                              sessionStorage.lockView = "gridView";
                            }}/>
                          </Tooltip>
                        :
                          <Tooltip placement='bottom' title="Toggle view" arrow>
                            <ViewList  sx={{color:"#fff",cursor:"pointer"}} onClick={()=>{
                              setLockView("listView")
                              sessionStorage.lockView = "listView";                            
                            }}/>
                          </Tooltip>
                      :
                        null  
                  :
                    null
                  :
                    null
                }
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ml: 2, color: checkDataExistsOrNot(props.primaryColor) ? props.primaryColor : primaryThemeColor}}
                  className="accountMenu"
                >
                  <AccountCircle sx={{height:"inherit", width:"inherit"}} />
                </IconButton>
              </Box>
              <Menu
                className="account_popover"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
              >
                <div className="row_1">
                  <div className="name">{sessionStorage.userDetails ? JSON.parse(sessionStorage.userDetails).user.firstName + " " + JSON.parse(sessionStorage.userDetails).user.lastName : ""}</div>
                  <div className="email">{sessionStorage.userDetails ? JSON.parse(sessionStorage.userDetails).user.email : ""}</div>
                </div>
                <Divider />
                {
                  sessionStorage.userDetails && JSON.parse(sessionStorage.userDetails).user.socialLogin === false ?
                    <Link
                      to="#/change-password"
                      style={{textDecoration: 'unset', color: 'black'}}
                      onClick={() => reloadPage('/change-password')}
                    >
                      <MenuItem>Change Password</MenuItem>
                    </Link>
                  :
                    null
                }
                <MenuItem onClick={(event) => {event.stopPropagation();}} sx={{display: JSON.parse(sessionStorage.userDetails).user.scopeId !== "66c8765e927a13321c085d4b" ? 'block' : 'none'}}>{!toggleState ? "Enable 2FA" : "Disable 2FA"}
                  <span style={{marginLeft: '29px'}}>
                     <Tooltip title={ toggleState ? "Two-Factor Authentication Enabled" : "Two-Factor Authentication Disabled"} placement="top" arrow>
                        <Switch size='medium' checked={toggleState} onClick={(event) => enableOrDisableTwoFa(event)} />
                     </Tooltip>
                  </span>
                </MenuItem>
                {
                  sessionStorage.isRootUser === "true" && sessionStorage.userDetails && JSON.parse(sessionStorage.userDetails).permissions.includes("IOT_DEVICE_SCOPE_MIGRATE") ?
                    <Link
                      to="#/upgrade-firmware"
                      style={{textDecoration: 'unset', color: 'black'}}
                      onClick={() => reloadPage('/upgrade-firmware')}
                    >
                      <MenuItem sx={{mt: 1}}>Update Gateway Firmware</MenuItem>
                    </Link>
                  :
                    null
                }
                {
                  sessionStorage.isRootUser === "true" && sessionStorage.userDetails && JSON.parse(sessionStorage.userDetails).permissions.includes("IOT_DEVICE_SCOPE_MIGRATE") ?
                    <Link
                      to="#/customer-firmware"
                      style={{textDecoration: 'unset', color: 'black'}}
                      onClick={() => reloadPage('/customer-firmware')}
                    >
                      <MenuItem sx={{mt: 1}}>Customer Firmware App</MenuItem>
                    </Link>
                  :
                    null
                }
                {
                  sessionStorage.isRootUser === "true" && sessionStorage.userDetails && JSON.parse(sessionStorage.userDetails).permissions.includes("IOT_DEVICE_SCOPE_MIGRATE") ?
                    <Link
                      to="#/update-OS"
                      style={{textDecoration: 'unset', color: 'black'}}
                      onClick={() => reloadPage('/update-OS')}
                    >
                      <MenuItem sx={{mt: 1}}>Update Gateway OS</MenuItem>
                    </Link>
                  :
                    null
                }
                {
                  sessionStorage.isRootUser === "true" && sessionStorage.userDetails && JSON.parse(sessionStorage.userDetails).permissions.includes("IOT_DEVICE_SCOPE_MIGRATE") ?
                    <Link
                      to="#/portal-config"
                      style={{textDecoration: 'unset', color: 'black'}}
                      onClick={() => reloadPage('/portal-config')}
                    >
                      <MenuItem sx={{mt: 1}}>Portal Configuration</MenuItem>
                    </Link>
                  :
                    null
                }
               {
                 sessionStorage.userDetails && JSON.parse(sessionStorage.userDetails).permissions.includes("SCOPE_UPDATE") && JSON.parse(sessionStorage.userDetails).user.scopeId !== "66c8765e927a13321c085d4b"?
                    <Link
                      to="#/theme-config"
                      style={{textDecoration: 'unset', color: 'black'}}
                      onClick={() => reloadPage('/theme-config')}
                    >
                      <MenuItem sx={{mt: 1}}>Theme Configuration</MenuItem>
                    </Link>
                  :
                  null
                }
                <MenuItem onClick={() => showRemoveUserAlertDialog()} sx={{display: JSON.parse(sessionStorage.userDetails).user.scopeId === "66c8765e927a13321c085d4b" ? 'block' : 'none'}}> Remove your account </MenuItem>
                <Link
                  to="#/logout"
                  style={{textDecoration: 'unset', color: 'black'}}
                  onClick={() => reloadPage('/logout')}
                >
                  <MenuItem>Logout</MenuItem>
                </Link>
              </Menu>
            </React.Fragment>
          ) : null}
        </div>
      </div>
      {
         enableTwoFaDialog ?
            <TwoFactorAuthentication
               openDialog={enableTwoFaDialog}
               setToggleToPreviousState={(res) => {
                  if (res === false) {
                    setToggleState(!toggleState);
                  }
               }}
               closeDialog={() => setEnableTwoFaDialog(false)}
            />
         :
         null
      }
      {
         disableTwoFaDialog ?
            <DisableTwoFactorAuthentication
               openDialog={disableTwoFaDialog}
               setToggleToPreviousState={() => setToggleState(!toggleState)}
               closeDialog={() => setDisableTwoFaDialog(false)}
            />
         :
         null
      }
      {
        showRemoveUserDialog ?
            <DeletePortalUsersDialog
                title="Remove Your Account"
                disable={false}
                data={JSON.parse(sessionStorage.userDetails).user}
                flag={true}
                show_delete_permission_dialog={showRemoveUserDialog}
                close_delete_permission_dialog={(value) =>
                  handleCloseRemoveUserAlertDialog(value)
                }
                dialog="deletePermissionDialog"
            />
        :
            null
      }
    </nav>
  );
};

export default Appheader;
