import { AddCircleOutline, ArrowBack, CheckCircle, CloudUpload, Delete, HelpOutline, InfoOutlined, RemoveCircleOutlined } from '@mui/icons-material';
import { Avatar, Box, Button, Checkbox, Chip, CircularProgress, FormControl, FormControlLabel, ListItemText, MenuItem, Radio, RadioGroup, Select, Snackbar, TextField, Tooltip } from '@mui/material';
import AuthClient from "clients/AuthClient";
import GatewayServiceClient from "clients/GatewayServiceClient.js";
import MqttEventHandler from 'components/Mqtt/MqttEventHandler';
import checkDataExistsOrNot from 'helper-components/checkDataExistsOrNot.js';
import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import 'stylesheet/UpdateFirmware.css';
import ApplyCustomerFirmwareDialog from './ApplyCustomerFirmwareDialog.js';
import ControlOsUpgradeDialog from './ControlOsUpgradeDialog.js';
import Appheader from 'components/header/Appheader';

export default function CustomerFirmware(){

    if (window.uploadOsResponseSuccess) {
      delete window.uploadOsResponseSuccess;
    }

    const [showSnackBar, setShowSnackBar] = React.useState(false);
    const [snackBarMessage, setSnackBarMessage] = React.useState("");
    const [selectedCustomer, setSelectedCustomer] = React.useState([]);
    const [selectedRadioButtonAction, setSelectedRadioButtonAction] = React.useState('Apply');
    const [allCustomerFwVersions, setAllCustomerFwVersions] = React.useState([]);
    const [isStagingEnv, setIsStagingEnv] = React.useState(window.location.href.indexOf('staging') !== -1);
    const [searchBlockText, setSearchBlockText] = React.useState('');
    const [customers, setCustomers] = React.useState(null);
    const [isCustomerDataLoading, setIsCustomerDataLoading] = React.useState(true);
    const [cfwFileNamePattern, setCfwFileNamePattern] = React.useState(new RegExp('^Customer_firmware_V(\\d{2}.\\d+)\\.zip$'));
    const [selectedCustomerFwVersion, setSelectedCustomerFwVersion] = React.useState([]);
    const [controlOsUpgradeDialog, setControlOsUpgradeDialog] = React.useState(false);
    const [checkedData, setCheckedData] = React.useState([]);
    const [defaultCustomerFwVersion, setDefaultCustomerFwVersion] = React.useState([]);
    const [descriptionText, setDescriptionText] = React.useState("");
    const [fileHasUploaded, setFileHasUploaded] = React.useState(false);
    const [validToSubmit, setValidToSubmit] = React.useState(false);
    const [showApplyCfwVersionDialog, setShowApplyCfwVersionDialog] = React.useState(false);
    const [showUploadCfwDialog, setShowUploadCfwDialog] = React.useState(false);
    const [showDeleteCfwVersionDialog, setShowDeleteCfwVersionDialog] = React.useState(false);
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    let fileUploaded = null;
    window.alreadyExistedVersion = false;
    window.fileFormatAccepted = false;
    const location = useLocation();


    const backToCustomerPage = () => {
          if(sessionStorage.isRootUser === "true"){
            window.location.hash= "/admin/tenants";
            location.hash= "#/admin/tenants";
          }else {
            window.location.hash= "/cli/dashboard";
            location.hash= "#/cli/dashboard";
          }
    }

    const handleDeleteChip = (name) => {
        changeAddRemoveIcon(name);
    };

    const handleSearchTextChange = (e) => {
       setSearchBlockText(e.target.value);
    }

    const handleActionRadioGroupChange = (event) => {
       setSelectedRadioButtonAction(event.target.value);
       if (event.target.value === "Upload") {
         setSelectedCustomerFwVersion(defaultCustomerFwVersion);
         setSelectedCustomer([]);
       } else if (event.target.value === "Apply") {
         setSelectedCustomer([]);
         setDescriptionText("");
         fileUploaded = null;
         let file = document.getElementById("fileInput");
         file.value = "";
         checkIsValidToSubmitOrNot();
       }
    }

    const handleSelectedCfwApplyOptionChange = (value) => {
      setSelectedCustomerFwVersion(value);
    }

    const getBackgroundColor = (tenant) => {
           let name = tenant + 's';
           var hash = 0;
           for (var i = 0; i < name.length; i++) {
             hash = name.charCodeAt(i) + ((hash << 5) - hash);
           }
           var color = (hash & 0x00ffffff).toString(16).toUpperCase();
           return '00000'.substring(0, 6 - color.length) + color;
    }

   const handleCloseOsUpgradeDialog = (value) => {
        _.forEach(customers,(user) =>{
               _.forEach(checkedData,(val)=>{
                   if(user.name===val.name){
                       user.enabledCheckbox=value;
                   }
               })
        })
        setControlOsUpgradeDialog(value);
        setSelectedCustomer([]);
   }

   const openControlOsUpdateDialog = (checked,element) => {
       let users=[];
        _.forEach(customers,(user) => {
               if(user.name === element.name){
                   user.enabledCheckbox=checked;
                   users.push(user);
               }
        })
        setCheckedData(users);
   }

    let changeAddRemoveIcon = (name) => {
       name = name.replaceAll('RemoveCircleOutlineIcon', '');
       var selectedCustomers = _.cloneDeep(Object.assign([],selectedCustomer));
       if (!selectedCustomers.includes(name)) {
          selectedCustomers.push(name);
          setSelectedCustomer(selectedCustomers);
       } else {
           var lastIndx = selectedCustomers.lastIndexOf(name);
           if (lastIndx > -1) {
              selectedCustomers.splice(lastIndx, 1);
           }
             setSelectedCustomer(selectedCustomers);
       }
    }

    const handleSelectAllCustomers = () => {
       var selectedCustomers = [];
       customers.map((element) => {
         selectedCustomers.push(element.name);
         setSelectedCustomer(selectedCustomers);
       });
    }

    const handleUnSelectAllCustomers = () => {
      var selectedCustomers = [];
      setSelectedCustomer(selectedCustomers);
    }

    const showToastMessage = (message) => {
            setShowSnackBar(true);
            setSnackBarMessage(message);
          setTimeout(() => {
              setShowSnackBar(false);
              setSnackBarMessage("");
          }, 2500);
    }

    const uploadCfwVersionsFile = (event, fileName) => {
        if (typeof event.target.files[0] !== 'undefined') {
          fileUploaded = event.target.files[0];
          window.fileUploaded = event.target.files[0];
          let uploadedFileName = fileUploaded !==  null && typeof fileUploaded !== 'undefined' ? fileUploaded.name : null;
          let fileFormateAccepted;
          if (checkDataExistsOrNot(uploadedFileName)) {
            //let version = uploadedFileName.substring(uploadedFileName.indexOf('_V')+2,uploadedFileName.indexOf('_V')+7).replace(".","");
            fileFormateAccepted = cfwFileNamePattern.test(uploadedFileName);
            window.fileFormatAccepted = fileFormateAccepted;
            window.version = uploadedFileName.substring(uploadedFileName.indexOf('_V')+2,uploadedFileName.indexOf('.'));
          } else {
            window.version = "";
          }

          let alreadyExistedVersion = false;
          if (checkDataExistsOrNot(uploadedFileName)) {
            _.forEach(allCustomerFwVersions, (versions) => {
               if (versions.version === Number(uploadedFileName.substring(uploadedFileName.indexOf('_V')+2,uploadedFileName.indexOf('.')))){
                  alreadyExistedVersion = true;
               }
            })
          }
          window.alreadyExistedVersion = alreadyExistedVersion;
          checkIsValidToSubmitOrNot();
        }
    }

    const checkIsValidToSubmitOrNot = () => {
        if (fileUploaded) {
          if (window.alreadyExistedVersion === false && window.fileFormatAccepted === true) {
            const osPatchFileReader = new FileReader();
            osPatchFileReader.addEventListener('load', function () {
              setFileHasUploaded(true);
              setValidToSubmit(true);
            });
            osPatchFileReader.readAsDataURL(fileUploaded);
          } else {
            setFileHasUploaded(false);
            setValidToSubmit(false);
            document.getElementById("fileInput").value = "";
            if (window.alreadyExistedVersion === true) {
              setShowSnackBar(true);
              setSnackBarMessage("Uploaded Customer Firmware version already exists in the tenant.Please choose APPLY instead of UPLOAD");
              setTimeout(() => {
                 setShowSnackBar(false);
                 setSnackBarMessage("");
              }, 2000);
            } else if (window.fileFormatAccepted === false) {
              setShowSnackBar(true);
              setSnackBarMessage("Invalid version number or file name");
              setTimeout(() => {
                 setShowSnackBar(false);
                 setSnackBarMessage("");
              }, 2000);
            }
          }
        } else {
          setFileHasUploaded(false);
          setValidToSubmit(false);
        }
    }

    const MenuProps = {
        PaperProps: {
          style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            maxWidth: 250,
          },
        },
    };

    const handleDisplayText = (cfwVersion) => {
        let version = cfwVersion.version;
        let date = moment(cfwVersion.date).format("MMM Do, YYYY h:mm:ss a")
        let string = " (" + date + ")";
        return version + string;
    }

    const handleApplyCustomerFwDialog = () => {
        setShowApplyCfwVersionDialog(true);
    }

    const handleChangeDescription = (event) => {
        setDescriptionText(event.target.value);
    }

    const handleUploadDialog = () => {
        setShowUploadCfwDialog(true);
    }

    const handleCloseApplyCfwDialog = () => {
      setShowApplyCfwVersionDialog(false);
    }

    const handleCloseUploadDialog = () => {
      setShowUploadCfwDialog(false);
    }

    const handleCloseDeleteDialog = () => {
      setShowDeleteCfwVersionDialog(false);
    }

   const fetchUpgradeOSData = () => {
        setIsCustomerDataLoading(true);
        if (!sessionStorage.userLoggedIn){
             window.location.hash = "/";
        }else {
            let filteredTenants = [];
            let filteredTenantsWithGateways = [];
            let cfwVersions = [];
            let defaultVersion = [];

            AuthClient.getHirarchy().then((data) => {
                  filteredTenants.push(data.scope);
                  _.forEach(data.children,(value)=>{
                     filteredTenants.push(value.scope);
                  })
                _.forEach(filteredTenants,(tenant) => {
                            tenant.enabledCheckbox=false;
                            filteredTenantsWithGateways.push(tenant);
                });
                AuthClient.getLatestVersionsForCustomerFW("UpdateCFW").then((versions) => {
                     if (versions.customerFWFileStore){
                         _.forEach(filteredTenantsWithGateways, (tenant) => {
                             tenant.versions = versions.customerFWFileStore[tenant.id] ? versions.customerFWFileStore[tenant.id] : [] ;
                         })
                     }

                     AuthClient.getAllCustomerFwVersions().then((allVersions) => {
                       if (checkDataExistsOrNot(allVersions)) {
                          cfwVersions = _.orderBy(_.uniqBy(allVersions,(cfwVersn) => cfwVersn.version),["date"],["desc"]);
                          if(checkDataExistsOrNot(cfwVersions)) {
                             defaultVersion.push(cfwVersions[0].version);
                          }
                          setDefaultCustomerFwVersion(defaultVersion);
                          setSelectedCustomerFwVersion(defaultVersion);
                          setAllCustomerFwVersions(cfwVersions);
                       }
                       setCustomers(filteredTenantsWithGateways);
                       setIsCustomerDataLoading(false);
                     });
                });
            });
        }
   }

   const cfwChannelEvent = () => {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
   }

   React.useEffect(()=>{
     // TODO : Need to change subscribing mqtt channel
     window.cfwMqttChannel = MqttEventHandler.subscribe("auth-service/+/customer-firmware-upgraded-details", "UpdateOs",cfwChannelEvent)
     fetchUpgradeOSData();

      return () => {
          if (window.cfwMqttChannel){
             window.cfwMqttChannel.unsubscribe();
             delete window.cfwMqttChannel;
          }
          if (window.uploadOsResponseSuccess) {
             delete window.uploadOsResponseSuccess;
          }

          if (window.cfwUpdateResponseSuccess) {
            delete window.cfwUpdateResponseSuccess;
          }

          GatewayServiceClient.abortSignal("UpdateOs");
          AuthClient.abortSignal("configureEnail");
      }
   },[]);

  return(
       <>
              <Appheader/>
              {
                showSnackBar ?
                <Snackbar
                  anchorOrigin={{ vertical:'bottom', horizontal:'right' }}
                  open={showSnackBar}
                  message={snackBarMessage}
                />
              :
                null
              }
             <div style={{height: '90%'}}>
                 <div style={{height: '56px'}}>
                     <ArrowBack
                        sx={{color:"#4190c7"}}
                        onClick={() => {
                          if(sessionStorage.isRootUser === "true"){
                             window.location.hash= "/admin/tenants";
                             location.hash= "#/admin/tenants";
                          }else {
                            window.location.hash= "/cli/dashboard";
                            location.hash= "#/cli/dashboard";
                          }
                        }}
                        className="backArrow"
                     />
                 </div>
                 <div className="container" style={{maxHeight: '95%'}}>
                   <div
                      className="subContainer"
                      style={{
                         minHeight: '100%',
                         marginRight: 'auto',
                         marginLeft: 'auto',
                      }}
                   >
                     <div
                         style={{
                            flex: '20',
                            padding: '0 12px',
                            borderBottom: '1px solid rgba(0,0,0,0.12)',
                         }}
                     >
                       <h5 style={{fontSize: '16px'}}>Customer Firmware App</h5>
                     </div>
                     <div className="subBlock" style={{flex: '60'}}>
                        <div className="portions" style={{width: '100%'}}>
                            { selectedCustomer.length > 0 ? (
                                 <div className="selectedBlockExist">
                                     { selectedCustomer.map((index) => {
                                         return (
                                            <Chip
                                              sx={{marginTop: '8px', marginLeft: '8px'}}
                                              label={index}
                                              onDelete={() => handleDeleteChip(index)}
                                            />
                                         );
                                     })}
                                 </div>
                            ) : (
                              <div className="selectedBlock">
                                  <span>Select a customer to update the Customer Firmware</span>
                              </div>
                            )}
                            <div className="radioGroup">
                              <FormControl component="fieldset" variant="standard">
                                 <RadioGroup
                                     defaultValue="Apply"
                                     row
                                     aria-label="emailType"
                                     name="row-radio-buttons-group"
                                     onChange={handleActionRadioGroupChange}
                                 >
                                   <div style={{display:'flex',flexDirection:'row',width:'100%'}}>
                                   <Tooltip title='Apply existing Customer Firmware' placement='top'>
                                   <div style={{flex:'50%'}}>
                                    <FormControlLabel
                                       value="Apply"
                                       control={
                                           <Radio
                                               sx={{
                                                 color: 'rgba(0,0,0,0.54)',
                                                 '&.Mui-checked': {
                                                 color: '#4190c7',
                                                 },
                                               }}
                                           />
                                       }
                                       label="Apply"
                                    />
                                    <HelpOutline sx={{fontSize:'18px',color:'#0000008a',position:'relative',marginBottom: '-4px',marginLeft:'-12px'}}/>
                                    </div>
                                    </Tooltip>
                                    <Tooltip title='Upload new Customer Firmware' placement='top'>
                                    <div style={{flex:'50%'}}>
                                    <FormControlLabel
                                       value="Upload"
                                       control={
                                         <Radio
                                            sx={{
                                               color: 'rgba(0,0,0,0.54)',
                                               '&.Mui-checked': {
                                                color: '#4190c7',
                                                },
                                            }}
                                         />
                                       }
                                       label="Upload"
                                    />
                                    <HelpOutline sx={{fontSize:'18px',color:'#0000008a',position:'relative',marginBottom: '-4px',marginLeft:'-12px'}}/>
                                    </div>
                                    </Tooltip>
                                    </div>
                                 </RadioGroup>
                                 <Box
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      margin: '8px',
                                    }}
                                 >
                                    <div style={{ display:
                                        selectedRadioButtonAction === 'Apply' ? 'block' : 'none', }}
                                    >
                                      <Box sx={{display: "flex", flexDirection: "column"}}>
                                        <Box className="firmwareUploadColumn" sx={{ display: 'flex', alignItems: 'center', margin: '8px', textAlign: 'center',}} >
                                           <Box sx={{display: 'flex',flexDirection:"row"}} >
                                              <b>Version:</b>
                                           </Box>
                                           <Box sx={{margin: "8px"}} className="firmwareSelect">
                                              <FormControl sx={{ m: 1, width: "100%"}}>
                                                 <Select
                                                    multiple
                                                    variant = "standard"
                                                    value = {selectedCustomerFwVersion}
                                                    onChange = {(event) => {handleSelectedCfwApplyOptionChange(event.target.value)}}
                                                    renderValue={(selected) => selected.join(", ")}
                                                    MenuProps={MenuProps}
                                                 >
                                                    {allCustomerFwVersions.map((version) => (
                                                      <MenuItem key={version.id} value={version.version}>
                                                        <Checkbox checked={selectedCustomerFwVersion.indexOf(version.version) > -1} />
                                                        <ListItemText primary={handleDisplayText(version)} />
                                                        <div><Tooltip title={version.description} placement='top' arrow>
                                                           <InfoOutlined sx={{display: checkDataExistsOrNot(version.description) ? 'block' : 'none', fontSize:'18px',color:'#0000008a',position:'relative',marginBottom: '-4px',marginLeft:'-12px'}}/>
                                                        </Tooltip></div>
                                                      </MenuItem>
                                                    ))}
                                                 </Select>
                                              </FormControl>
                                           </Box>
                                        </Box>
                                        <Box className="applyAndUploadDiv" sx={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", padding: "5px", margin: "20px 10px 10px 10px"}}>
                                          <Button
                                            variant="outlined"
                                            disabled = {selectedCustomer.length !== 0 ? selectedCustomerFwVersion.length === 1 ? false : true : true}
                                            onClick = {() => {handleApplyCustomerFwDialog()}}
                                            style = {{color: selectedCustomerFwVersion.length === 1 && selectedCustomer.length !== 0 ? "#fff" : 'rgba(0,0,0,0.12)', margin: '2px', padding: "5px 20px", fontWeight: "bold", background: selectedCustomerFwVersion.length === 1 && selectedCustomer.length !==0 ? '#4190c7' : 'rgba(0,0,0,0.12)'}}
                                          >
                                            <CheckCircle style={{marginRight: "5px",fontSize: "22px"}}/> APPLY
                                          </Button>
                                          <Button
                                            variant="outlined"
                                            disabled = {selectedCustomerFwVersion.length === 0}
                                            onClick = {() => setShowDeleteCfwVersionDialog(true)}
                                            style = {{color: selectedCustomerFwVersion.length !== 0 ? "#fff" : 'rgba(0,0,0,0.12)', margin: '2px', padding: "5px 15px", fontWeight:'600', background: selectedCustomerFwVersion.length !== 0 ? '#4190c7' : 'rgba(0,0,0,0.12)'}}
                                          >
                                            <Delete style={{marginRight: "5px",fontSize: "22px"}}/> DELETE
                                          </Button>
                                        </Box>
                                      </Box>
                                    </div>
                                    <div style={{ display: selectedRadioButtonAction === 'Upload' ? 'block' : 'none',}}>
                                      <Box sx={{display: 'flex',flexDirection:"column",margin: "8px",alignItems: "center"}}>
                                        <Box
                                          sx={{
                                             display: 'flex',
                                             alignItems: 'center',
                                             margin: '8px',
                                             textAlign: 'center',
                                             padding: "10px",
                                             width: "100%"
                                          }}
                                        >
                                          <Tooltip placement="top" title={
                                            <div style={{ whiteSpace: 'pre-line',fontSize:"10px"}}>
                                                {"Expected File Name Format: \n Customer_firmware_Vxx.xx.zip"}
                                            </div>
                                          } arrow>
                                            <Button
                                              component="label"
                                              variant="outlined"
                                              sx={{
                                                '&.MuiButtonBase-root:hover': {
                                                 background: '#4190c7',
                                                 },
                                               width: '100%', color: "#fff", margin: '6px', background: "#4190c7"
                                              }}
                                            >
                                               {fileHasUploaded ? "FILE UPLOADED" : "UPLOAD FILE"}
                                               <input  id="fileInput" type="file" accept=".zip" key={fileUploaded} value={fileUploaded} hidden onChange={(event)=>uploadCfwVersionsFile(event)} />
                                            </Button>
                                          </Tooltip>
                                        </Box>
                                        <Box sx={{alignItems: 'center', margin: '8px', padding: "10px", textAlign: 'center',width: "100%"}}>
                                          <TextField
                                            inputProps={{ maxLength: 20 }}
                                            label="Description"
                                            variant="standard"
                                            value={descriptionText}
                                            onChange={(event) => {handleChangeDescription(event)}}
                                            sx={{width: '95%'}}
                                          />
                                        </Box>
                                        <Box sx={{margin: '8px', textAlign: 'center'}}>
                                          <Button
                                            variant="outlined"
                                            disabled={!validToSubmit}
                                            onClick={() => {handleUploadDialog()}}
                                            style={{color: validToSubmit ? "#fff" : 'rgba(0,0,0,0.12)', margin: '2px', padding: "5px 10px", fontWeight:'600', background: validToSubmit ? '#4190c7' : 'rgba(0,0,0,0.12)'}}
                                          >
                                            <CloudUpload style={{marginRight: "5px",fontSize: "22px"}}/> UPLOAD
                                          </Button>
                                        </Box>
                                      </Box>
                                    </div>
                                 </Box>
                              </FormControl>
                            </div>
                        </div>
                        <div
                           className="portions tenantsBlock"
                           style={{
                             margin: 'auto',
                             height: '100%',
                             width: '95%',
                           }}
                        >
                           <div className="inputSearchBlock" style={{padding: '7px', display: 'initial',display:'flex', flexDirection: 'row',height: '100%'}}>
                               <input
                                    type="text"
                                    className="searchDiv"
                                    //value={value}
                                    onChange={handleSearchTextChange}
                                    placeholder="Search"
                                    autoFocus
                                    style={{
                                        textIndent: '4px',
                                        height: '34px',
                                        fontSize: '100%',
                                        flex: '50%',
                                        width: '100%'
                                    }}
                               />
                               <div className = "selectiveDivParent" style={{ flex: '40%'}}>
                                 <div className = "selectiveDiv" style={{display: selectedCustomer.length > 0 ? 'block': 'none', color: '#4190c7',marginLeft:'35px',marginTop:'55px',fontWeight:'600'}}>
                                     SELECTIVE UPDATE
                                 </div>
                               </div>
                             <div className = "buttonsDiv" style={{ display: 'flex', flex: '40%', flexDirection: 'column' }}>
                                  <Button
                                       style={{
                                           color: '#4190c7',
                                           margin: '2px',
                                           fontWeight: '600',
                                           flexDirection: 'column'
                                       }}
                                       onClick={() => handleSelectAllCustomers()}
                                  >
                                     SELECT ALL
                                  </Button>
                                  <Button
                                       style={{
                                           color: '#4190c7',
                                           margin: '2px',
                                           fontWeight: '600',
                                           flexDirection: 'column'
                                       }}
                                       onClick={() => handleUnSelectAllCustomers()}
                                  >
                                     UNSELECT ALL
                                  </Button>
                             </div>
                           </div>
                           <div className="allTenants" style={{height:"100%"}}>
                             {
                                !isCustomerDataLoading ?
                                  // eslint-disable-next-line array-callback-return
                                  customers ? customers
                                      .sort((first, next) =>
                                             first.name.localeCompare(next.name)
                                      ).map((element, index) => {
                                                       if (index >= 0) {
                                                         if (
                                                           element.name
                                                             .toLowerCase()
                                                             .lastIndexOf(
                                                               searchBlockText.toLowerCase()
                                                             ) !== -1
                                                         ) {
                                                             return (
                                                                <div
                                                                   key={element.name}
                                                                   className="tenantItems"
                                                                   style={{minHeight: '72px', height: 'auto',
                                                                   marginBottom: '10px', marginTop: '5px'}}
                                                                >
                                                                   <div
                                                                       style={{
                                                                          width: '96px',
                                                                          height: '100%',
                                                                          alignItems: 'center',
                                                                          marginRight: '10px',
                                                                          display: 'flex',
                                                                          flex: '10%'
                                                                       }}
                                                                   >
                                                                     <Avatar
                                                                        id="avatar"
                                                                        style={{
                                                                           margin: ' 10px 4px 10px 24px',
                                                                           padding: '4px',
                                                                           background:
                                                                            '#' +
                                                                           getBackgroundColor(
                                                                              element.name
                                                                           ),
                                                                        }}
                                                                     >
                                                                         {element.name[0].toUpperCase()}
                                                                     </Avatar>
                                                                   </div>
                                                                   <div
                                                                       style={{
                                                                           marginTop: 'auto',
                                                                           marginBottom: 'auto',
                                                                           width: '50%',
                                                                           flex: '40%'
                                                                       }}
                                                                   >
                                                                      {element.name}
                                                                      {
                                                                        <div style={{
                                                                            marginTop: '5px',
                                                                            color: 'gray',
                                                                            fontSize: '12px',
                                                                        }}>
                                                                          {
                                                                            element.versions ?
                                                                              element.versions.map((value) => {
                                                                                return(
                                                                                  value.type === "CustomerFW" ?
                                                                                    <>
                                                                                      {value.version + " ("+ moment(value.date).format("MMM Do, YYYY h:mm:ss a") + ")"}
                                                                                    </>
                                                                                  :
                                                                                    null
                                                                                );
                                                                              })
                                                                            :
                                                                              null
                                                                          }
                                                                        </div>
                                                                      }
                                                                   </div>
                                                                   <div style={{margin: 'auto 100px auto 0px',display: 'flex', flexDirection: 'row',flex: '30%'}}>
                                                                      <div className = "checkboxDiv" style={{ marginRight: '80%' }}>
                                                                        <Checkbox checked={element.enabledCheckbox}
                                                                            onClick={(event) => { openControlOsUpdateDialog(event.target.checked,element)
                                                                               setControlOsUpgradeDialog(true)
                                                                            }}
                                                                            sx={{display: selectedCustomer.includes(element.name) ? 'block' :'none', margin: '0px', padding: '0px',left: "-3px"}}
                                                                        />
                                                                      </div>
                                                                   </div>
                                                                   <div style={{margin: 'auto 5px auto 0px',flex: '20%'}}>
                                                                      {
                                                                         <>
                                                                           <Tooltip title='Add Customer' placement='top'>
                                                                            <AddCircleOutline
                                                                                 id={element.name}
                                                                                 style={{
                                                                                     display:
                                                                                     selectedCustomer.includes(
                                                                                        element.name
                                                                                     )
                                                                                     ? 'none'
                                                                                     : 'block',
                                                                                 }}
                                                                                 onClick={() =>
                                                                                    changeAddRemoveIcon(element.name)
                                                                                 }
                                                                                 sx={{color: 'rgba(0,0,0,0.54)'}}
                                                                            />
                                                                            </Tooltip>
                                                                           <Tooltip title='Remove Customer' placement='top'>
                                                                            <RemoveCircleOutlined
                                                                                 className="removeCircle"
                                                                                 style={{
                                                                                      display:
                                                                                        selectedCustomer.includes(
                                                                                             element.name
                                                                                        )
                                                                                      ? 'block'
                                                                                      : 'none',
                                                                                      color: 'rgba(0,0,0,0.54)',
                                                                                 }}
                                                                                 id={
                                                                                      element.name +
                                                                                       'RemoveCircleOutlineIcon'
                                                                                 }
                                                                                 onClick={() =>
                                                                                      changeAddRemoveIcon(
                                                                                           element.name +
                                                                                              'RemoveCircleOutlineIcon'
                                                                                      )
                                                                                 }
                                                                            />
                                                                            </Tooltip>
                                                                         </>
                                                                      }
                                                                   </div>
                                                                </div>

                                                             );

                                                         }
                                                       }
                                      })
                                  : null
                                :
                                <Box sx={{width:'100%',display:'flex',justifyContent: 'center',alignItems:'center',minHeight: '72px', height: '100%'}}>
                                    <CircularProgress thickness="4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                                </Box>
                             }
                           </div>
                        </div>
                     </div>
                     <div className="bottomDiv" style={{}}>
                       <Button style={{color: '#4190c7', margin: '2px'}} onClick={()=>backToCustomerPage()}>
                           BACK TO CUSTOMERS
                       </Button>
                     </div>
                   </div>
                 </div>
                 {controlOsUpgradeDialog ? (
                    <>
                      <ControlOsUpgradeDialog
                          show_control_os_dialog={controlOsUpgradeDialog}
                          data = {checkedData}
                          closeDialog={(value) => handleCloseOsUpgradeDialog(value)}
                          dialog = "controlOsUpgradeDialog"
                      />
                    </>
                 ) : null}

                 {showApplyCfwVersionDialog ? (
                   <ApplyCustomerFirmwareDialog
                     show_apply_cfw_dialog={showApplyCfwVersionDialog}
                     allVersions={allCustomerFwVersions}
                     allCustomers={customers}
                     selectedCustomers={selectedCustomer}
                     selectedVersion={selectedCustomerFwVersion}
                     cfwUpdateSuccess={(val) => {window.cfwUpdateResponseSuccess=val}}
                     closeDialog={() => handleCloseApplyCfwDialog()}
                     title="Apply Customer Firmware"
                     dialog="applyCfwVersionDialog"
                   />
                 ) : null}

                 {showUploadCfwDialog ? (
                   <ApplyCustomerFirmwareDialog
                     show_upload_dialog={showUploadCfwDialog}
                     allCustomers={customers}
                     selectedCustomers={selectedCustomer}
                     description={descriptionText}
                     file={window.fileUploaded}
                     version={window.version}
                     cfwUpdateSuccess={(val) => {window.cfwUpdateResponseSuccess=val}}
                     closeDialog={() => handleCloseUploadDialog()}
                     title="Upload Customer Firmware"
                     dialog="uploadCfwDialog"
                   />
                 ) : null}

                 {showDeleteCfwVersionDialog ? (
                   <ApplyCustomerFirmwareDialog
                      show_delete_dialog={showDeleteCfwVersionDialog}
                      selectedVersion={selectedCustomerFwVersion}
                      cfwUpdateSuccess={(val) => {window.cfwUpdateResponseSuccess=val}}
                      closeDialog={() => handleCloseDeleteDialog()}
                      title="Delete Customer Firmware"
                      dialog="deleteCfwDialog"
                   />
                 ) : null}
             </div>
       </>
  );
}